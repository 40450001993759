.ql-container {
  height: 116px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'Ubuntu';
  font-size: 16px;
}

.quill {
  border-radius: 5px;
}

.ql-toolbar {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
