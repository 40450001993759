@import '/src/app/theme.scss';
@import '/src/app/mixins.scss';
@import '../../grid.scss';

.container {
  @include AmenitiesGrid;

  &:not(:last-child) {
    border-bottom: 1px solid $border;
  }
}

.editButton {
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px;
}

.text {
  font-size: 14px;
  color: $text-primary;
}

.radio {
  margin: auto 0;

  input {
    @include CustomRadioColor($text-secondary);
    cursor: not-allowed;
  }
}

.widget {
  border: none;
  box-shadow: none;
  background-color: transparent !important;

  & > div {
    display: none !important;
  }

  & svg > path {
    fill: $text-secondary !important;
  }
}
