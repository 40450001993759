@mixin CustomRadioColor($color) {
  appearance: none;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: 'solid';
  border-color: $color !important;
  border-radius: 50%;
  padding: 3px;
  background-color: transparent !important;
  background-clip: content-box;

  &:focus {
    box-shadow: none;
  }

  &:checked {
    background-color: $color !important;
  }

  &:disabled {
    border-color: #d9d9d9 !important;
  }

  &:disabled:checked {
    background-color: #b9c5d0 !important;
  }
}
