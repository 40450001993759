.container {
  width: 100%;
  display: flex;
  column-gap: 24px;
}

.button {
  width: 48px !important;
  height: 40px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  justify-content: center;

  svg {
    align-self: center;
    color: #6a6fb2 !important;
  }
}

.select {
  width: 328px;
  min-height: 40px;
  max-height: 40px;

  & > :global(.ant-select-selector) {
    min-height: 40px !important;
    max-height: 40px !important;
    height: 100% !important;
    display: flex;
    align-items: center;
  }

  & :global(.ant-select-selection-item) {
    white-space: nowrap !important;
    text-overflow: ellipsis;
  }
}

.tooltipOverlay {
  max-width: 500px;
}
