@import '/src/app/theme.scss';

.container {
  margin-bottom: 26px;

  span {
    color: $text-primary;
    font-size: 32px;
    line-height: 38px;
    font-weight: 400;
    letter-spacing: 0.16px;
  }
}
