@import '/src/app/theme.scss';

.RatingRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Score {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: 6px;
  padding: 2px 4px;
}

.Green {
  background-color: $green;
}

.Orange {
  background-color: $orange;
}

.Red {
  background-color: rgba($red, 0.7);
}

.NotRatedText {
  text-align: center;
}

.CommentText {
  max-width: 270px;
  max-height: 300px;
  overflow-y: auto;
}

.CommentText::-webkit-scrollbar {
  display: none;
}
.CommentText {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
