@import '/src/app/theme.scss';

.StatusRow {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;
}

.RedCircle circle {
  fill: $red;
}
