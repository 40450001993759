@import '/src/app/theme.scss';

.TableContainer {
  background: transparent;
  padding-top: 20px;
}

.pageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-family: 'Ubuntu';
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.pageContainer {
  padding: 28px 20px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.pageContent {
  width: 1131px;
  min-height: 680px;
  border-radius: 6px;
  background: $white;
}

.iconContainer {
  img {
    margin-top: 123px;
    margin-left: 460px;
  }
}

.pageSubTitleWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  span {
    font-family: 'Ubuntu';
    font-size: 20px;
    color: $text-primary;
    font-style: normal;
    line-height: 23px;
  }
}

.pageDescription {
  display: flex;
  width: 653px;
  height: 72px;
  margin-top: 18px;
  margin-left: 239px;
  text-align: center;

  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    font-style: normal;
    line-height: 24px;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px !important;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.linkText {
  color: $primary !important;
}

.ColoredText {
  color: $primary;
}

.TooltipButton {
  width: 48px !important;
  height: 40px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  justify-content: center;

  svg {
    align-self: center;
    color: #6a6fb2 !important;
  }
}

.TooltipOverlay {
  max-width: 500px;
}

.ToolBarWrapper {
  gap: 24px;
}

.TipText {
  color: $white;
}
