@import '/src/app/theme.scss';

.PageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.PageContainer {
  padding: 28px 20px 60px 0px;
  min-height: 700px;
  background-color: $page-background;
}

.PageContent {
  width: 1131px;
  min-height: 680px;
  border-radius: 6px;
  background: $white;
}

.FiltersPanel {
  align-items: center;
  margin-bottom: 12px;
}

.InputOrgNameSelector {
  margin-right: 16px;
  margin-left: 16px;

  .FormInput {
    width: 328px;
    height: 40px;
  }

  :global {
    .ant-select-selector {
      padding-top: 5px !important; // to override the default antd hover style
      height: 40px !important; // to override the default antd hover style
    }
  }
}

.AddActionButton.AddActionButton {
  margin-right: 4px;
  width: 48px;
  height: 40px;
  border-radius: 6px;

  &:active {
    border-color: transparent;
  }

  &:hover {
    border-color: transparent !important; // to override the default antd hover style
  }
}

.PromotionsTable {
  display: flex;
  flex-direction: column;
}

.BannerWrapper.BannerWrapper {
  height: 37.38px;
  width: 58px;
}

.CenteredContent.CenteredContent.CenteredContent {
  text-align: center;
}

.GreenCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $green;
  align-self: center;
  margin-right: 12px;
}

.RedCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $red;
  align-self: center;
  margin-right: 12px;
}

.GreyCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $pale-grey;
  align-self: center;
  margin-right: 12px;
}

.LifeTimeLabel {
  display: flex;
}

.LinkText.LinkText {
  color: $primary;

  &:hover {
    color: $primary;
  }
}
