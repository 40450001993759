@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 20px 60px 0;
  background-color: $page-background;
}

.infoBlock {
  color: $primary;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  padding: 34px;
  max-width: 858px;
  margin-bottom: 20px;
}

.infoNote {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $primary;
  width: 299px;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $primary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
  svg path {
    stroke: $primary;
  }
}

.buttonSubmit {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: $primary;

  &:hover {
    background: $button-hover;
  }

  span {
    color: $white;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.paymentSubtitle {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.singleInputRow {
  max-width: 418px;
}

.inputOrgName {
  width: 100%;
  max-width: 328px;
  height: 40px;

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-top: 5px;
      height: 40px;
    }
  }
}

.blockContainer {
  margin-top: 16px;
  width: 100%;
  width: 1131px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;

  input {
    height: 56px;
    width: 100%;
  }

  textarea {
    padding-top: 15px;
    width: 100%;
    resize: none;
  }
}

.blockTitle {
  margin-bottom: 32px;

  h3 {
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-weight: normal;
    line-height: 24px;
    margin: 0;
  }
}

.singleInputRowWrapper {
  gap: 24px;
  flex-wrap: nowrap;

  & :global {
    .ant-col,
    .ant-row {
      width: 100%;
    }
  }
}

