@import '/src/app/theme.scss';

.PageContainer {
  padding: 28px 20px 60px 0px;
  min-height: 700px;
  background-color: $page-background;
}

.PageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.CardsContainer {
  max-width: 1196px;
}

.MenuItemWrapper {
  width: 1016px;
  margin-top: 43px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;

  div {
    cursor: pointer;
    height: 40px;

    span {
      font-size: 16px;
      color: $text-primary;
      font-style: normal;
      line-height: 20px;
      font-weight: 300;
    }
  }
}
