@import '/src/app/theme.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.list {
  width: 460px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.position {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.position {
  width: 100%;
  display: flex;
}

.select {
  width: 417px;
}

.remove {
  cursor: pointer;
}

.add {
  margin-top: 16px;
  width: 500px;
  height: min-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: $primary;
  user-select: none;
  cursor: pointer;
}

.cursordefult {
  cursor: default;
}
