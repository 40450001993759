@import '/src/app/theme.scss';

.StatisticBlock {
  background-color: $white;
  flex-basis: 543px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(128, 132, 193, 0.1);
  padding: 23px 37px;
}

.IconWrapper img:global(.ant-image-img) {
  width: 103px;
  height: 93px;
}

.SubRow,
.TopRow {
  align-items: center;
}

.TopRow {
  justify-content: space-between;
}

.SubRow {
  gap: 20px;
}

.BottomRow {
  gap: 16px;
  margin-top: 5px;
  flex-wrap: wrap;
}

.StatisticWrapper {
  flex-grow: 1;
  min-width: 48%;
}

.Title {
  font-size: 20px;
  max-width: 211px;
}

.Button {
  display: flex;
  width: 105px;
  height: 48px;
  border-radius: 6px;
  background: rgba($primary, 0.12);
  align-items: center;
  justify-content: center;
  color: $primary;
  font-size: 16px;

  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}

.Statistic {
  border: 1px solid rgba(231, 231, 241, 1);
  padding: 11px 20px;
  border-radius: 6px;

  :global(.ant-statistic-title) {
    color: $text-primary;
    font-weight: 300;
  }
}

.Minimized {
  min-width: 30%;
}

.StatisticValue {
  font-size: 24px;
}

.RedHighlight {
  background-color: rgba($red, 0.7);
  color: $white;
  border-radius: 6px;
  padding: 0 4px;
}
