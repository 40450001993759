@import '/src/app/theme.scss';

.container {
  border-radius: 6px;
  background: $white;
  padding: 40px 39px 43px 32px;
}

.header {
  margin-left: 16px;
}

.dividerWrapper {
  margin-top: 39px;
}

.divider {
  height: 1px;
  background: #dadada;
}

.profile {
  padding: 13px 16px;
  display: flex;
  align-items: center;
  position: relative;

  &__name {
    margin-left: 30px;
    color: $text-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
  }

  &__specialization {
    display: flex;
    flex-direction: column;
    margin-left: 85px;
    color: $text-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
  }

  &__photo {
    border-radius: 6px;
    object-fit: cover;
  }

  &__button {
    position: absolute;
    right: 17px;
  }
}

.supportBlock {
  padding-top: 30px;
  padding-left: 16px;
}
