@import '/src/app/theme.scss';

.ServiceComponentWrapper {
  background: transparent;
  padding: 24px 0px;
}

.TabsContainer {
  margin-top: 20px;
  :global(.ant-tabs-tab) {
    height: 36px;
  }
}
.Tabs {
  height: 35px;
  width: 588px;
}

.ServiceName {
  font-size: 32px;
}

.ServiceNameWithoutSideBar {
  padding-left: 40px;
}
