.root {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 54px;

  & :global(.ant-select-selector) {
    min-height: 54px;
    height: auto !important;
    word-break: keep-all;
    display: flex;
    align-items: center;
    padding: 12px !important;
  }

  & :global(.ant-select-selection-placeholder) {
    font-size: 14px;
    line-height: 20px !important;
  }

  & :global(.ant-select-selection-item) {
    word-break: keep-all;
    white-space: normal !important;
  }

  & :global(.ant-select-selection-search-input) {
    height: 100% !important;
  }
}

.optionWrap {
  width: 100%;
  height: auto;
  word-break: keep-all;
  white-space: normal !important;

  & :global(.ant-select-item-option-content) {
    word-break: keep-all;
    white-space: normal !important;
  }
}
