@import '/src/app/theme.scss';

.container {
  position: relative;
  margin-top: 33px;
  min-height: 1000px;
  background-color: $white;
  padding: 32px;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(128, 132, 193, 0.1);
}

.collapseRoot {
  & :global(.ant-collapse-header) {
    height: 80px;
    border-bottom: 1px solid $border;
    background-color: $white;
    display: flex;
    align-items: center !important;
  }

  & :global(.ant-collapse-expand-icon) > svg {
    transition: 0.2s;
  }

  & :global(.ant-collapse-content) {
    // border-bottom: 1px solid $border;
    border-radius: 0 !important;
    background-color: $white !important;
  }

  & :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }
}

.rotate {
  transform: rotateX(180deg);
}
