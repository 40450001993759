@import '/src/app/theme.scss';

.pageContainer {
  padding: 24px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.generalDataContainer {
  width: 1131px;
  min-height: 273px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.generalDataColorTitle {
  display: flex;
  align-items: center;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.selectorColorWrapper {
  :global {
    .ant-select-selector {
      height: 56px !important;
    }
    .ant-select-selection-item {
      padding-top: 10px !important;
    }
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 334px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonSubmitDisabled {
  width: 334px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.logoPhotoRow {
  width: 145px;
  justify-content: center;
}

.backgroundPhotoRow {
  margin-top: 8px;
}

.fileUploader {
  img {
    object-fit: cover;
    border-radius: 6px;
  }

  p {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    width: 67px;
    margin-top: 40px;
  }

  :global {
    .ant-upload-drag {
      height: 136px;
      width: 145px;
      background: #ecedf6;
      border-radius: 6px;
      padding: 0 !important;
    }
    .ant-upload-btn {
      display: inline !important;
    }
    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
  }
}

.fileBackgroundUploader {
  img {
    object-fit: cover;
    border-radius: 6px;
  }

  p {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    width: 100%;
    margin-top: 40px;
  }

  :global {
    .ant-upload-drag {
      height: 126px;
      width: 410px;
      background: #ecedf6;
      border-radius: 6px;
      padding: 0 !important;
    }
    .ant-upload-btn {
      display: inline !important;
    }
    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
  }
}

.imgPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 8px;
  height: 118px;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}

.bgPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 8px;
  height: 108px;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}

.removePhotoLabel {
  cursor: pointer;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;

    &:hover {
      color: darken(#6266a7, 20%);
    }
  }
}

.inputsRowWrapper {
  width: 1047px;
  justify-content: space-between;
}

.inputsRowDoctorsWrapper {
  width: 1047px;
  justify-content: flex-start;
  gap: 25px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.generalDataFormSubTitle {
  margin-bottom: 0;
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.branchesNamesList {
  display: flex;
  flex-direction: column;
  max-width: 440px;

  margin-top: 32px;
}

.branchNameChkbWrapper {
  margin-bottom: 10px;

  span:not(:first-child) {
    margin-left: 8px;
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }

  :global {
    span.ant-checkbox-inner {
      margin-left: 0;
    }
  }
}

.singleFormRow {
  min-width: 872px;
  justify-content: space-between;
  margin-left: 12px;
}

.bannerForm {
  width: 1131px;
  min-height: 474px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
}

.bannerVisibilityChkbWrapper {
  span:not(:first-child) {
    margin-left: 8px;
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }

  :global {
    span.ant-checkbox-inner {
      margin-left: 0;
    }
  }
}

.colorForm {
  width: 1131px;
  min-height: 208px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
}

.agreementsForm {
  width: 1131px;
  min-height: 369px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
}

.colorSelectorWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 115px;
}

.coloredSquare {
  width: 24px;
  height: 24px;
}

.colorPallete {
  width: 300px;
}

.singleColorFormRow {
  min-width: 872px;
  margin-left: 12px;

  svg {
    margin-left: 18px;
    align-self: center;
  }
}

.colorFormRow {
  margin-left: 12px;

  svg {
    margin-left: 18px;
    align-self: center;
  }
}

.widgetCodeForm {
  width: 1131px;
  min-height: 451px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 49px;
}

.widgetCodeBtnsWrapper {
  margin-left: 12px;
  width: 100%;
}

.buttonShowWidget {
  width: 306px;
  height: 32px;
  border-radius: 6px;
  background-color: $primary !important;

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.buttonCopyCode {
  width: 149px;
  height: 32px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.inputButtonNameWrapper {
  margin-left: 12px;
  margin-top: 32px;
}

.instructionDescAccordion {
  margin-left: 12px;
  margin-top: 20px;

  :global {
    .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-header-text {
      color: $primary;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 24px;
    }
  }

  &:hover {
    :global {
      .ant-collapse-header-text {
        color: darken(#6266a7, 20%);
      }

      .ant-collapse-expand-icon {
        svg path {
          fill: darken(#6266a7, 20%);
        }
      }
    }
  }
}

.rotatedIcon {
  transform: rotate(180deg);
}

.instructionText {
  color: $primary;
  font-family: 'Ubuntu';
  font-size: 14px;
  line-height: 24px;
}

.agreementsFormSubTitle {
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.buttonUploadAgreement {
  width: 315px;
  height: 32px;
  border-radius: 6px;
  background-color: $primary !important;

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.agreementUploadButtonContainer {
  margin-left: 12px;
  margin-top: 21px;
  margin-bottom: 35px;
}

.addAgreementsFormSubTitle {
  display: flex;
  flex-direction: column;
  max-width: 800px;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.buttonUploadUserAgreement {
  width: auto;
  height: 32px;
  border-radius: 6px;
  background-color: $primary !important;

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.buttonUploadUserAgreementDisabled {
  width: 135px;
  height: 32px;
  border-radius: 6px;
  background-color: rgba(118, 120, 134, 0.16) !important;

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    opacity: 0.38;
  }

  svg {
    margin-right: 10px;
    color: $text-primary;

    path {
      fill: $text-primary;
      opacity: 0.38;
    }
  }
}

.agreementUploadUserButtonContainer {
  margin-left: 12px;
  margin-top: 21px;
  margin-bottom: 35px;
  min-width: 800px;
}

.docNameWrapper {
  display: flex;
  align-self: center;

  svg {
    align-self: center;
    cursor: pointer;
  }

  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
  }

  span:not(:first-child) {
    margin-left: 8px;
  }
}

.removeDocumentIcon {
  margin-left: 28.7px;
}

.docInfoWrapper {
  display: flex;
}

.checkboxesContainer {
  display: flex;
  flex-wrap: wrap;
}

.Input {
  height: 56px;
  width: 417px;
}

.widgetCodeSubTitle {
  margin-bottom: 20px;
  margin-left: 12px;
  margin-top: 20px;
  span {
    font-size: 18px;
  }
}

.widgetDoctorsText {
  margin-left: 12px;
  width: 590px;
}

.widgetCodeInfoContainer {
  display: flex;
  justify-content: space-between;
  border: 1px solid $border;
  border-radius: 6px;
  padding: 10px 20px;
  align-items: center;
  max-width: 860px;
  margin-left: 12px;
}

.DoctorsList.DoctorsList {
  width: 860px;
  margin-top: 29px;
  border: 1px solid $border;
  border-radius: 6px;
  margin-left: 12px;

  :global {
    .ant-list {
      width: 100%;
    }

    .ant-list-item-meta-content {
      margin-left: 16px;
    }

    .ant-list-item {
      padding: 12px 21px 36px 21px;
      min-height: 85px;
    }

    .ant-list-item-meta {
      align-items: center;
      margin-top: 24px;
      min-width: 400px;
      max-width: 400px;
    }
  }
}

.DoctorNameText {
  font-size: 14px;
  letter-spacing: 0.2px;
  color: $primary;
  font-style: normal;
  line-height: 18px;
  font-weight: 400;
}

.DoctorPhoto.DoctorPhoto {
  width: 58px;
  height: 58px;
}

.ContentWrapper {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.CopyButtonWrapper {
  display: flex;
  align-items: center;
}

.DoctorsListContainer {
  min-height: 250px;
}

.infoBlock {
  color: $primary;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  padding: 34px;
  max-width: 858px;
  margin-bottom: 20px;
}

.infoNote {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $primary;
  width: 392px;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $primary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
  svg path {
    stroke: $primary;
  }
}