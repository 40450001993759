@import '/src/app/theme.scss';

.ErrorPageContainer {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 24px;
}

.ErrorPageTitle {
  font-size: 20px;
}

.ErrorPageDescription {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  max-width: 280px;
}
