@import '/src/app/theme.scss';

.pageContainer {
  min-height: 700px;
  background: transparent;
  padding-bottom: 30px;
  margin-top: 32px;
}

.cellLinkText {
  color: $primary;
  a {
    font-size: 14px;
    color: $primary;
  }
}

.RecordsTable {
  padding-top: 24px;
}

.ColTitle {
  font-weight: 500;
  font-size: 16px;
}

.PopupCol {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.InputLabel {
  width: 100%;
  max-width: 400px;

  label {
    padding-top: 0 !important;
  }
}

.InputsRow {
  flex-wrap: nowrap;
  gap: 16px;
}