@import '/src/app/theme.scss';

.ButtonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.ButtonSubmit {
  display: block;
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.ButtonSubmit:hover {
  color: $white;
  background-color: rgba($primary, 0.08);
  border: 1px solid;
  color: $primary;
}

.FormButtonsContainer {
  justify-content: center;
}
