@import '/src/app/theme.scss';

.StatusSelect.StatusSelect {
  width: 185px;
}

.StatusSelect :global(.ant-select-selection-item) {
  display: flex;
  align-items: center;
}
