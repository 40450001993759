@import '/src/app/theme.scss';

.AboutServiceWrapper {
  padding-top: 34px;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1131px;
}

.AboutServiceBlock {
  background-color: $white;
  box-shadow: 0px 0px 20px rgba(128, 132, 193, 0.1);
  border-radius: 6px;
  padding: 40px 48px;
}

.Title {
  font-size: 24px;
}

.Text {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  max-width: 880px;
}

.SubTitle {
  font-size: 18px;
  color: $text-primary;
  margin-top: 24px;
}

.SubCol {
  margin-left: 14px;
}

.AboutServiceTopRow {
  margin-top: 32px;
  gap: 65px;
}

.AboutServiceDescriptionRow {
  margin-top: 44px;

  ul {
    list-style-type: disc;
  }

  li::marker {
    color: $primary;
  }
}

.AboutServiceTextRow {
  margin-top: 28px;
}

.ButtonsRow {
  gap: 20px;
  justify-content: center;
}

.ButtonsBottomRow {
  gap: 20px;
  justify-content: center;
  margin: 20px 0 86px;
}

.AboutList {
  ::marker {
    color: $primary;
    font-size: 15px;
  }
}

.AboutListText {
  font-size: 16px;
  font-weight: 300;
  max-width: 840px;
}

.FaqTitle {
  font-size: 18px;
  color: $primary;
}

.FaqText {
  font-size: 16px;
  font-weight: 300;
  max-width: 840px;
}

.FaqCollapse {
  width: 100%;
  border-block-start: 0px;
}

.FaqPanel {
  border-block-start: 1px solid $border;
  border-radius: 0px;
  padding-top: 24px;
  margin-top: 16px;
}

.CrossIcon {
  margin-right: -6px;
}

.Carousel {
  :global(.slick-arrow) {
    display: block;
    width: 36px;
    height: 36px;
  }
  :global(.slick-next) {
    margin-right: 42px;
  }
  :global(.slick-prev) {
    margin-left: 42px;
    z-index: 1;
  }
}

.CheckPrimalIcon {
  path {
    fill: $white;
  }
}

.SubmitText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.CollapsePanel.CollapsePanel.CollapsePanel {
  & div {
    width: max-content;
    padding-left: 0;
  }

  h3 {
    font-weight: 400;
    margin-top: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
  }

  :global {
    .row {
      display: flex;
      max-width: 100%;
      gap: 20px;
    }

    .col {
      display: flex;
      flex-direction: column;
      max-width: 50%;
    }
  }
}

.CollapseHeader {
  color: $primary;
  font-weight: 300;
  font-size: 16px;
}

.rotatedIcon {
  transform: rotate(180deg);
}

.ServiceImage {
  height: 320px !important;
  width: 100% !important;
  content: '';
}
