@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.contentContainer {
  position: relative;
  min-width: 1131px;
  min-height: 740px;
  height: auto;
  margin-top: 33px;
  padding: 40px 32px;
  background-color: $white;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  border-radius: 6px;
}
