@import '/src/app/theme.scss';

.container {
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding-bottom: 30px;
  border-bottom: 1px solid $border;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: $text-primary;
}
