@import '/src/app/theme.scss';

.infoBlock {
  color: $primary;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  padding: 34px;
  max-width: 858px;
  margin-bottom: 20px;
}

.infoNote {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $primary;
  width: 268px;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $primary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
  svg path {
    stroke: $primary;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 22px;
  margin-bottom: 22px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    &[disabled] {
      background: rgba($text-secondary, 0.16);
      opacity: 0.5;
      span {
        color: $text-primary;
      }
    }
  }
}

.Title {
  font-size: 32px;
  margin-bottom: 31px;
}

.BranchesSelector {
  width: 418px;
  
  :global {
    div.ant-select-selector.ant-select-selector {
      height: 50px;
      padding: 8px 11px;
    }
  }
}

.generalDataContainer {
  width: 1131px;
  min-height: 432px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 24px 40px 48px;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.ScheduleWrapper {
  padding: 24px 40px 30px;
  background: transparent;
}

.NoScheduleWrapper {
  height: 100vh;
}