.TMScheduleContainer {
    background-color: white;
    padding: 10px;

    :global(.Footer) {
        position: inherit !important;
    }

    :global(.PaginatorContainer) {
        position: inherit !important;
    }
}

.TMPaddingContainer {
    padding: 10px 42px;
}
