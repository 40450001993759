@import '/src/app/theme.scss';

.titleDoctorsName {
  margin-bottom: 26px;

  span {
    color: $text-primary;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.16px;
  }
}

.pageContainer {
  padding: 24px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.pageContentContainer {
  min-width: 1131px;
  min-height: 671px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 32px;
  margin-top: 24px;

  & :global {
    .ant-table-thead svg {
      margin-left: 6px;
      margin-bottom: -4px;
    }
  }
}

.pageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.pageContent {
  width: 1131px;
  min-height: 680px;
  border-radius: 6px;
  background: $white;
}

.iconContainer {
  img {
    margin-top: 123px;
    margin-left: 460px;
  }
}

.pageSubTitleWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  span {
    font-size: 20px;
    color: $text-primary;
    font-style: normal;
    line-height: 23px;
  }
}

.pageDescription {
  display: flex;
  width: 653px;
  min-height: 72px;
  margin-top: 18px;
  margin-left: 239px;
  text-align: center;

  span {
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    font-style: normal;
    line-height: 24px;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    span {
      color: $white;
      font-size: 16px !important;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.linkText {
  color: $primary !important;
}

.serviceNameText {
  font-size: 24px;
  letter-spacing: 0.2px;
  color: $text-primary;
  font-style: normal;
  line-height: 18px;
}

.servicesNumberText {
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #767886;
  font-style: normal;
  line-height: 18px;
  margin-left: 16px;
}

.tableRowButton {
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px;
}

.misNameCell {
  max-width: 150px;

  p {
    white-space: nowrap;
    z-index: 10;
    position: relative;
  }
}

:global {
  th.ant-table-cell {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary !important;
    font-style: normal;
    line-height: 24px;
    font-weight: 500 !important;
  }
}

.editableCell {
  margin-bottom: 0 !important;

  textarea,
  input {
    font-size: 14px !important;
  }
}

.tableRowSaveButton {
  background: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px;
}

.cellWidgetVisibility {
  text-align: center;
}

.cellWithCheckbox {
  text-align: center;
}

.editableCheckBox {
  margin: auto 0;
}

.disabledCheckbox {
  :global {
    .ant-checkbox-inner {
      background-color: #bebfc8 !important;
    }
  }
}

.ageCellWrapper {
  display: flex;
  // min-width: 228px;

  span:first-child {
    margin-right: 20px;
  }
}

.ageSelector {
  :global {
    .ant-select-selector {
      padding-top: 5px !important;
      height: 40px !important;
    }
  }
}

.ageCellFormWrapper {
  display: flex;
  width: 182px;
  justify-content: space-between;
  height: 40px;
}

.visibilityButton {
  border: none;
  box-shadow: none;
  background-color: transparent !important;
}

.buttonCell {
  padding: 0 !important;
}

.expandableMisNameCell {
  width: 160px !important;
}

.misNameTooltipText {
  span {
    color: $white;
  }
}
