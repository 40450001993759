@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.pageContentContainer {
  min-width: 1131px;
  min-height: 671px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 32px;
  margin-top: 24px;
}

.inputOrgName {
  margin-right: 16px;
  margin-left: 16px;

  :global {
    .ant-select-selector {
      padding-top: 5px !important;
      height: 40px !important;
    }
  }
}

.titleUserName {
  margin-bottom: 26px;

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.16px;
  }
}

.generalDataContainer {
  width: 1131px;
  min-height: 432px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 24px 40px 48px;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.userRightsSubTitle {
  margin-bottom: 16px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.branchesFormContainer {
  width: 1131px;
  min-height: 213px;
  padding: 40px 48px;
  margin-top: 16px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;

  :global {
    .ant-checkbox {
      margin-right: 8px;
    }
  }
}

.userRightsFormContainer {
  width: 1131px;
  min-height: 101px;
  padding: 40px 48px;
  margin-top: 16px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;

  :global {
    .ant-checkbox {
      margin-right: 8px;
    }
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonSubmitDisabled {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.selectorWrapper {
  :global {
    .ant-select-selector {
      height: 56px !important;
    }
    .ant-select-selection-item {
      padding-top: 15px !important;
    }

    .ant-select-arrow {
      left: 356px;
      z-index: 10;
    }
  }
}

.switchAccessButton {
  background-color: rgba(98, 102, 167, 0.12);
  border-radius: 6px;
  min-width: 177px !important;
  height: 40px;
  display: flex;
  margin-top: 32px;

  svg {
    align-self: center;
    min-width: 18px;
  }

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin-left: 5.77px;
    align-self: center;
  }
}

.accessStatus {
  padding: 0 !important;
  margin: 0 auto;
}

.accessStatusColumn {
  display: flex;
  flex-direction: column;
}

.checkboxesContainer {
  display: flex;
  flex-wrap: wrap;
}

.pageAccessFormItem {
  margin-bottom: 11px;
}

.pageAccessGroupWrapper {
  margin-bottom: 48px;
}

.linkTextWrapper {
  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    margin-right: 5px;
  }

  a {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    text-decoration: underline;

    &:hover {
      color: $primary !important;
    }
  }
}

.hiddenClaim {
  display: none;
}
