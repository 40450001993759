@import '/src/app/theme.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
}

.button {
  width: 48px !important;
  height: 40px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  justify-content: center;

  svg {
    align-self: center;
    color: #6a6fb2 !important;
  }
}

.field {
  flex: 1;
  min-height: 40px;
}

.select {
  width: 100%;
  min-height: 40px;
  max-height: 40px;

  & > :global(.ant-select-selector) {
    min-height: 40px !important;
    max-height: 40px !important;
    height: 100% !important;
  }

  & :global(.ant-select-selection-item) {
    white-space: nowrap !important;
    text-overflow: ellipsis;
  }
}

.excelButton {
  width: 100%;
  height: 40px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.1px;
    color: $primary;
    font-style: normal;
    line-height: 20px;
  }

  svg {
    margin-right: 14px;
  }
}
