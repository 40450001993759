@import '/src/app/theme.scss';

.Floatlabel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;

  :global(.ant-form-item),
  input {
    width: 100%;
  }

  :global {
    .ant-input-disabled,
    .ant-input[disabled],
    input[disabled],
    .ant-picker .ant-picker-input > input[disabled] {
      color: $disabledInputColor;
      border-color: $inputBorder;
    }
  }
}

:global {
  .ant-select-disabled .ant-select-selector .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.25);
  }
}

.Label {
  font-size: 14px;
  line-height: 114%;
  color: $text-secondary;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 12px;
  transition: 0.2s ease all;
  padding-top: 6px;
  background-color: transparent;
  z-index: 2;
}

.LabelClassDisabled {
  background-color: $disabledInput;
}

.Labelfloat {
  top: 3px;
  font-size: 11px !important;
}

.SuffixIcon {
  position: absolute;
  z-index: 1;
  right: -11px;
  top: 18px;
}
