@import '/src/app/theme.scss';

.pageContainer {
  padding: 24px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.titleDoctorsName {
  margin-bottom: 26px;

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.16px;
  }
}

.customTabsWrapper {
  width: 1131px !important;
  height: 36px !important;
}

.generalDataContainer {
  width: 1131px;
  min-height: 691px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.inputsWrapper {
  width: 418px;

  input {
    width: 418px;
  }
}

.inputsRowWrapper {
  width: 1047px;
  justify-content: space-between;
}

.inputsHalfRow {
  width: 418px;
  display: flex;
  justify-content: space-between;
}

.selectorWrapper {
  :global {
    .ant-select-selector {
      height: 56px !important;
    }
    .ant-select-selection-item {
      padding-top: 15px !important;
    }
  }
}

.fileUploader {
  img {
    object-fit: cover;
    border-radius: 6px;
  }

  p {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  :global {
    .ant-upload-drag {
      height: 153px;
      width: 153px;
      background: #ecedf6;
      border-radius: 6px;
      padding: 0 !important;
    }
    .ant-upload-btn {
      display: inline !important;
    }
    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
  }
}

.doctorPhotoRow {
  width: 153px;
  justify-content: center;
}

.removePhotoLabel {
  cursor: pointer;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
  }
}

.generalDataSpecTitle {
  margin-bottom: 16px;

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
}

.inputsSpecRowWrapper {
  width: 860px;
  justify-content: flex-start;
}

.specializationsDoctorsWrapper {
  display: flex;
}

.branchNameChkbWrapper {
  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
}

.inputsChildrenChkb {
  margin-left: 24px;
  display: flex;
  flex-direction: row;
  height: 56px;

  :first-child {
    margin-right: 6px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }

    .ant-col {
      margin: auto;
    }
  }
}

.inputAboutDoctor {
  textarea {
    padding-top: 24px !important;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;
  margin-left: 0 !important;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.deleteDoctorFromBranch {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(250, 96, 96, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonSubmitDisabled {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.educationFormContainer {
  width: 1131px;
  min-height: 168px;
  padding: 40px 48px;
  margin-top: 16px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
}

.singleFormRow {
  //margin-left: 12px;
  min-width: 872px;
}

.inputEducationType {
  :global {
    .ant-select-selector {
      height: 56px !important;
    }
    .ant-select-selection-item {
      padding-top: 15px !important;
    }
  }
}

.secondElementForm {
  margin-left: 24px;
}

.addInstitution {
  display: flex;
  align-items: center;
}

.addInstitutionTextWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 13px;
  }

  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
  }

  &:hover {
    span {
      color: darken(#6266a7, 20%);
    }

    svg path {
      stroke: darken(#6266a7, 20%);
    }
  }
}

.newInstitutionForm {
  margin-bottom: 24px;

  svg {
    margin-top: 15px;
    margin-left: 5px;
  }

  :global {
    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}

.uploadDocumentForm {
  :global {
    .anticon-paper-clip {
      display: none;
    }
    .ant-upload-list-text {
      margin-top: 16px;
    }
  }
}

.downloadBtnWrapper {
  width: 169px;
  height: 32px;
  border-radius: 6px;
  background: $primary;

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.servicesFormContainer {
  width: 1131px;
  min-height: 168px;
  padding: 40px 48px;
  margin-top: 16px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;

  :global {
    .ant-checkbox {
      margin-right: 8px;
    }
  }
}

.checkboxesContainer {
  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
}

.pageLoader {
  margin-top: 150px;
  display: block;
  min-height: 700px;
}

.docNameWrapper {
  display: flex;

  svg {
    align-self: center;
    cursor: pointer;
  }

  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
  }

  span:not(:first-child) {
    margin-left: 8px;
  }
}

.pageContentContainer {
  min-width: 1131px;
  min-height: 671px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 32px;
  margin-top: 24px;
}

.cellLinkText {
  a {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $primary !important;
    font-style: normal;
    line-height: 18px;
  }
}

.cellWidgetVisibility {
  text-align: center;
}

.TabContainer {
  border: 1px solid $primary;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  padding: 1px;
  width: 1131px;
  margin-bottom: 24px;
}

div.TabContainer.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab) {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1 1 0;
  color: $primary;
  background: transparent;
  border: none;
}

.TabContainer :global(.ant-tabs-nav),
.TabContainer :global(.ant-tabs-nav-wrap),
.TabContainer :global(.ant-tabs-nav-list) {
  width: 100%;
}

div.TabContainer.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab-active) {
  background-color: $primary;
  color: $white;
}

div.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):hover,
div.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):active {
  color: $primary;
}

:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn.ant-tabs-tab-btn) {
  color: $white;
}

.Active {
  background-color: $primary;
  color: $white;
}

.doctorPhotoContainer {
  img {
    object-fit: cover;
  }
}

.tooltipTitle {
  margin-left: 16px;
  align-self: center;
}

.imgPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 135px;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}

.searchInput {
  margin: auto 0;
}

.toolbarButton {
  width: 48px !important;
  height: 40px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  justify-content: center;

  svg {
    align-self: center;
    color: #6a6fb2 !important;
  }
}

.exportButton {
  width: 104px;
  height: 40px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.1px;
    color: $primary;
    font-style: normal;
    line-height: 20px;
  }

  svg {
    margin-right: 14px;
  }
}

.toolbarButtonsRow {
  display: flex;

  width: 443px;
  justify-content: space-between;
  margin-left: auto;
}

.filtersButton {
  width: 214px;
  height: 40px;
  background: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 61px;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.1px;
    color: $white;
    font-style: normal;
    line-height: 20px;
  }

  svg {
    margin-right: 12px;
  }
}

.inputOrgName {
  margin-right: 16px;

  :global {
    .ant-select-selector {
      padding-top: 5px !important;
      height: 40px !important;
    }
  }
}

.generalDataHasAccessTitle {
  margin-bottom: 20px;

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
}

.generalDataHasAccessInput {
  margin-bottom: 10px;

  input {
    margin-bottom: 5px;
  }
}

.generalDataHasAccessButton {
  margin-bottom: 30px;

  button {
    border-radius: 6px;
    background: $primary;
  }

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.addDoctorButton {
  height: 40px;
  width: 48px !important;
  padding: 0;
  margin-right: 16px;

  > * {
    margin-top: -0.5px;
  }

  &:hover {
    border-color: transparent !important;
  }
}
