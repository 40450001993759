@import '/src/app/theme.scss';

.container {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  margin-top: 34px;
  margin-bottom: 51px;
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonCheck {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: $primary;

  &:not([disabled]):hover {
    background: $button-hover;
  }

  span {
    color: $white;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}
