$light: #f4f4f6;
$pale-grey: #9fa2b4;

$primary: #6266a7;
$button-hover: #6e72ae;
$text-primary: #50515b;
$text-secondary: #767886;
$text-tertiary: #bebfc8;
$page-background: #f9f9fc;
$block-background: #ecedf4;
$border: #dadada;
$light-border: #e7e7f1;
$white: #ffffff;
$red: #fa6060;
$green: #a6c763;
$orange: #e79e44;
$card-background-hover: #6266a729;
$disabledInput: #f6f6f6;
$disabledInputColor: #444444;
$inputBorder: #e0e6eb;
$chatsSvg: #292D32;
