@import '/src/app/theme.scss';

.NavTabsContainer :global(.ant-tabs-nav) {
  margin: 0px;
}

.NavTabsContainer :global(.ant-tabs-tab-btn) {
  color: $text-primary;
  font-size: 16px;
  font-weight: 300;
}

.NavTabsContainer :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn.ant-tabs-tab-btn) {
  color: $text-primary;
}

.NavTabsContainer :global(.ant-tabs-ink-bar) {
  border-bottom: 4px #a6c763 solid;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.NavTabsContainer :global(.ant-tabs-nav::before) {
  border-bottom: 4px $border solid;
}

.NavTabsContainer :global(.ant-tabs-tab:hover),
.NavTabsContainer :global(.ant-tabs-tab-btn:focus:not(:focus-visible)),
.NavTabsContainer :global(.ant-tabs-tab-btn:active) {
  color: $text-primary;
}
