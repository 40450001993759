@import '/src/app/theme.scss';

.TableContainer {
  width: 100%;
  background: transparent;
  padding-bottom: 30px;
  margin-top: 32px;
}

.ColoredText {
  color: $primary;
}

.EditButton {
  cursor: pointer;
}

.RecordsTable {
  padding-top: 24px;
}

.InputLabel {
  width: 100%;
  max-width: 400px;

  label {
    padding-top: 0 !important;
  }
}

.InputsRow {
  flex-wrap: nowrap;
  gap: 16px;
}