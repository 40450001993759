@import '/src/app/theme.scss';

.ModalTitle {
  text-align: center;
  font-size: 18px;
}

.ConfirmEditModal :global(.ant-modal-footer) {
  text-align: center;
}
