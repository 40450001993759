@import '/src/app/theme.scss';

.pageContainer {
  padding: 0 40px 60px 40px;
  min-height: 500px;
  background-color: $page-background;
}

.bannerForm {
  width: 1131px;
  min-height: 274px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.inputsRowWrapper {
  width: 947px;
  :global {
    .ant-row.ant-form-item-row {
      width: 100%;
      min-width: 400px;
      max-width: 980px;
    }
    .ant-select.ant-select-in-form-item {
      width: 100% !important;
      height: fit-content !important;
    }
    .ant-select-selector {
      min-height: 40px;
    }
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 334px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.infoBlock {
  background: $block-background;
  padding: 21px 34px;
  max-width: 820px;
  margin-bottom: 24px;

  a {
    color: $primary;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  & > :global(.ant-typography) {
    color: $primary;
    font-size: 14px;
    line-height: 20px;
  }
}