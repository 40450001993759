@import '/src/app/theme.scss';

.titleDoctorsName {
  margin-bottom: 26px;

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.16px;
  }
}

.pageContainer {
  padding: 24px 20px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.pageContent {
  width: 1131px;
  min-height: 680px;
  border-radius: 6px;
  background: $white;
}

.pageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-family: 'Ubuntu';
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.iconContainer {
  img {
    margin-top: 123px;
    margin-left: 460px;
  }
}

.pageSubTitleWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  span {
    font-family: 'Ubuntu';
    font-size: 20px;
    color: $text-primary;
    font-style: normal;
    line-height: 23px;
  }
}

.pageDescription {
  display: flex;
  width: 653px;
  min-height: 72px;
  margin-top: 18px;
  margin-left: 239px;
  text-align: center;

  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    font-style: normal;
    line-height: 24px;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px !important;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.linkText {
  color: $primary !important;
  font-family: 'Ubuntu';
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 24px;
}

.paginationWrapper {
  display: flex;
  // justify-content: flex-end;
  // width: 1131px;
  margin-top: 24px;

  :global {
    .ant-pagination-total-text {
      margin-right: auto !important;
    }
  }
}

.totalPaginationResults {
  font-family: 'Ubuntu';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: $text-primary;
  font-style: normal;
  line-height: 20px;
}

.greenCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $green;
  align-self: center;
  margin-right: 8px;
}

.statusTextWrapper {
  display: flex;
}
