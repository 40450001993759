@import '/src/app/theme.scss';

.root {
  & :global(.ant-collapse-header) {
    height: 84px;
    border-bottom: 1px solid $border;
    background-color: $white;
    display: flex;
    align-items: center !important;
  }

  & :global(.ant-collapse-expand-icon) > svg {
    transition: 0.2s;
  }

  & :global(.ant-collapse-content) {
    border-bottom: 1px solid $border;
    border-radius: 0 !important;
    background-color: $white !important;
  }

  & :global(.ant-collapse-content-box) {
    padding: 22px !important;
  }
}

.rotate {
  transform: rotateX(180deg);
}
