@import '/src/app/theme.scss';

.container {
  border-radius: 6px;
  background: $white;
  padding: 40px 39px 43px 48px;
}

.description {
  margin-top: 29px;
  color: $text-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.profile {
  padding: 13px 16px;
}

.addDoctorToClinic {
  margin-top: 26px;
}

.supportBlock {
  padding-top: 38px;
}
