.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select {
  width: 417px;
}

.crossSignIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.checkbox {
  margin-left: 30px;
}
