@import '/src/app/theme.scss';

.PrimalButton {
  width: 223px;
  height: 48px;
  border-radius: 6px;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  :global(.ant-typography) {
    color: $white;
  }
}

.PrimalButton:hover {
  background-color: rgba($primary, 0.08);
  border: 1px solid;

  :global(.ant-typography) {
    color: $primary;
  }
}

.SecondaryButton {
  width: 223px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-size: 16px;

  :global(.ant-typography) {
    color: $primary;
  }
}

.SecondaryButton:hover {
  background-color: rgba($primary, 0.08);
  border: 1px solid;
}
