@import '/src/app/theme.scss';

.container {
  margin-left: 20px;
  display: flex;
  align-items: center;
  column-gap: 21px;
}

.title {
  font-size: 24px;
  color: $text-primary;
}

.count {
  font-size: 16px;
  color: $text-secondary;
}
