@import '/src/app/theme.scss';

.pageContainer {
  padding: 24px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.generalDataContainer {
  width: 1131px;
  min-height: 273px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.selectorColorWrapper {
  :global {
    .ant-select-selector {
      height: 56px !important;
    }
    .ant-select-selection-item {
      padding-top: 10px !important;
    }
  }
}

.saveButtons {
  display: flex;
}

.formButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 213px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonDemo {
  width: 430px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonSubmitDisabled {
  width: 213px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.logoPhotoRow {
  width: 960px;
  margin-bottom: 32px;
}

.logoPhotoCol {
  margin-right: 25px;
}

.fileUploader {
  img {
    object-fit: cover;
    border-radius: 6px;
  }

  p {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    width: 67px;
    margin-top: 40px;
  }

  :global {
    .ant-upload-drag {
      height: 136px;
      width: 145px;
      background: #ecedf6;
      border-radius: 6px;
      padding: 0 !important;
    }
    .ant-upload-btn {
      display: inline !important;
    }
    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
  }
}

.imgPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 8px;
  height: 118px;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}

.removePhotoLabel {
  cursor: pointer;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;

    &:hover {
      color: darken(#6266a7, 20%);
    }
  }
}

.inputsRowWrapper {
  width: 1047px;
  justify-content: space-between;
}

.agreementsRowWrapper {
  width: 1047px;
  flex-direction: column;
  padding-left: 12px;
}

.generalDataFormSubTitle {
  margin-bottom: 32px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.branchesNamesList {
  display: flex;
  flex-direction: column;
  max-width: 440px;

  margin-top: 32px;
}

.branchNameChkbWrapper {
  margin-bottom: 0;

  span:not(:first-child) {
    margin-left: 8px;
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }

  :global {
    span.ant-checkbox-inner {
      margin-left: 0;
    }
  }
}

.singleFormRow {
  min-width: 872px;
  justify-content: space-between;
  margin-left: 12px;
}

.colorForm {
  width: 1131px;
  min-height: 208px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
}

.agreementsForm {
  width: 1131px;
  min-height: 369px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
}

.colorSelectorWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 115px;
}

.coloredSquare {
  width: 24px;
  height: 24px;
}

.colorPallete {
  width: 300px;
}

.colorsFormRow {
  min-width: 872px;
  margin-left: 12px;
  margin-bottom: 25px;
}

.textFormRow {
  max-width: 195px;
}

.agreementsFormSubTitle {
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Ubuntu';
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.buttonUploadAgreement {
  width: 170px;
  height: 32px;
  border-radius: 6px;
  background-color: $primary !important;

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.agreementUploadButtonContainer {
  margin-top: 21px;
  margin-bottom: 35px;
}

.docNameWrapper {
  display: flex;
  align-self: center;

  svg {
    align-self: center;
    cursor: pointer;
  }

  span {
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
  }

  span:not(:first-child) {
    margin-left: 8px;
  }
}

.docInfoWrapper {
  display: flex;
}

.checkboxesContainer {
  display: flex;
  flex-wrap: wrap;
}

.Input {
  height: 56px;
  width: 417px;
}

.minboxTitle {
  height: 56px;
  width: 100%;
}

.blockContainer {
  margin-top: 16px;
  width: 100%;
  width: 1131px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;

  input {
    height: 56px;
    width: 100%;
  }

  textarea {
    padding-top: 15px;
    width: 100%;
    resize: none;
  }
}

.blockTitle {
  margin-bottom: 32px;

  h3 {
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-weight: normal;
    line-height: 24px;
    margin: 0;
  }
}

.newEmailButtonWrapper {
  margin-left: 29px;
  min-width: 179px;
}

.addNewBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 14px;

  svg {
    margin-right: 30px;
  }

  span {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
  }

  &:hover {
    span {
      color: darken(#6266a7, 20%);
    }

    svg path {
      stroke: darken(#6266a7, 20%);
    }
  }
}

.inputsMailWrapper {
  margin-top: 24px;
  display: flex;

  & :global {
    .ant-col {
      width: 100%;
      max-width: 418px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}

.mailsListSubTitle {
  span {
    color: $text-primary;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
}

.deleteButton {
  position: absolute;
  top: 25%;
  left: 100%;
}

.TextEditorWrapper {
  width: 859px;
  border-radius: 5px;
  padding-bottom: 24px;
  
  :global(.ql-container) {
    height: 180px;
  }
}

.TextEditorInfoText {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  span {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }
}

.popconfirm {
  width: 400px;
}
