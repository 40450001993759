@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 20px 60px 0;
  min-height: 2000px;
  background-color: $page-background;
}

.blockContainer {
  margin-top: 16px;
  width: 100%;
  width: 1131px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;

  input {
    height: 56px;
    width: 100%;
  }

  textarea {
    padding-top: 15px;
    width: 100%;
    resize: none;
  }
}

.blockTitle {
  margin-bottom: 32px;

  h3 {
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-weight: normal;
    line-height: 24px;
    margin: 0;
  }
}

.inputsWrapper {
  // width: 100%;
  min-width: 417px;

  :global(.ant-form-item) {
    // min-width: 417px;
  }
}

.inputsRowWrapper {
  width: 100%;
  max-width: 860px;
  justify-content: space-between;
  flex-wrap: nowrap;
  column-gap: 24px;

  &.inputsRowWrapperCol {
    flex-direction: column;
  }

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 56px;
      max-width: 418px;
      width: 100%;
    }
  }
}

.downloadBtnWrapper {
  width: 100%;
  max-width: 169px;
  height: 32px;
  border-radius: 6px;
  background: $primary;
  margin-left: 12px;

  span {
    color: $white;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.fullName :global {
  textarea.ant-input {
    height: 112px;
  }

  :global(.ant-input) {
    padding-top: 18px;
  }
}

.description :global {
  .ant-input-textarea-show-count > textarea.ant-input {
    height: 283px;
  }
  .ant-input {
    padding-top: 18px;
  }
}

.workingHours :global {
  .ant-input-textarea-show-count > textarea.ant-input {
    height: 252px;
  }
  .ant-input {
    padding-top: 18px;
  }
}

.addressForPatients {
  max-width: 418px;

  :global(.ant-input) {
    padding-right: 36px;
  }
}

.howToGetThere :global {
  textarea.ant-input {
    height: 132px;
  }
}

.parking :global {
  textarea.ant-input {
    height: 132px;
  }
}

.inputOrgName {
  width: 100%;
  max-width: 328px;
  height: 40px;

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-top: 5px;
      height: 40px;
    }
  }
}

.inputAge {
  :global {
    .ant-row.ant-form-item-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .ant-row .ant-form-item .ant-form-item-label > label {
      font-size: 16px;
    }

    .ant-typography {
      margin-right: 28px;
      padding-bottom: 0;
      font-size: 16px;
      line-height: 30px;
    }

    .ant-checkbox-wrapper {
      margin-right: 16px;
    }

    .ant-col.ant-form-item-control {
      width: auto;

      label {
        font-size: 16px;
      }
    }
  }
}

.photosFormTitle {
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }

  svg {
    margin-left: 11px;
    margin-right: 44px;
  }
}

.uploadForm {
  margin-top: 16px;
  width: 227px;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  align-self: flex-start;
}

.fileUploader,
.fileLogoUploader {
  img {
    object-fit: cover;
    border-radius: 6px;
  }

  p {
    color: $primary;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  :global {
    .ant-upload-drag {
      height: 96px;
      width: 96px;
      background: #ecedf6;
      border-radius: 6px;
      padding: 0 !important;

      .ant-upload-drag-container {
        p {
          padding-top: 10px;
        }
      }
    }
    .ant-upload-btn {
      display: inline !important;
    }
    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
    .ant-upload-list-item-action {
      display: none;
    }
    .ant-upload-list-item-name {
      padding: 0 !important;
    }
  }
}

.fileLogoUploader {
  :global {
    .ant-upload-drag {
      height: 136px;
      width: 145px;
    }
    .ant-upload {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .ant-upload-drag-container {
      p {
        padding: 10px;
      }
    }

    .ant-upload-drag .ant-upload {
      padding: 0;
    }
  }
}

.uploadTitle {
  align-self: flex-start;
  display: flex;
  padding: 0 !important;
  align-items: center;
}

.latLongWrapper {
  display: flex;
  padding: 0 !important;
  min-width: 418px;
  justify-content: space-between;
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.mailsListSubTitle {
  span {
    color: $text-primary;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }
}

.inputsMailWrapper {
  margin-top: 24px;
  display: flex;

  & :global {
    .ant-col {
      width: 100%;
      max-width: 418px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}

.addNewBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 14px;

  svg {
    margin-right: 30px;
  }

  span {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
  }

  &:hover {
    span {
      color: darken(#6266a7, 20%);
    }

    svg path {
      stroke: darken(#6266a7, 20%);
    }
  }
}

.inputParkingWrapper {
  padding: 0 !important;
}

.newEmailButtonWrapper {
  margin-left: 29px;
  min-width: 179px;
}

.singleInputRowWrapper {
  gap: 24px;
  flex-wrap: nowrap;

  & :global {
    .ant-col,
    .ant-row {
      width: 100%;
    }
  }
}

.removePhotoIcon {
  cursor: pointer;
  position: absolute;
  left: 65px;
  top: 5px;
  z-index: 2;
}

.photoFormContainer {
  display: flex;
  margin-right: 10px;
  position: relative;
}

.uploadDocumentForm {
  :global {
    .anticon-paper-clip {
      display: none;
    }
    .ant-upload-list-text {
      margin-top: 16px;
    }
  }
}

.docNameWrapper {
  display: flex;

  svg {
    align-self: center;
    cursor: pointer;
  }

  span {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
  }

  span:not(:first-child) {
    margin-left: 8px;
  }
}

.buttonSubmitDisabled {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.deleteButton {
  position: absolute;
  top: 25%;
  left: 100%;
}

.RemoveLogo {
  color: $primary;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

.subwaySelectInput {
  :global(.ant-select-clear) {
    right: 36px;
    scale: 1.4;
  }
}

.PhonesCol {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.imgPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 8px;
  height: 118px;
  justify-content: flex-end;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}

.imgSmallPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 4px;
  height: 88px;
  justify-content: flex-end;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}
