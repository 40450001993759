@import '/src/app/theme.scss';

.NotificationWrapper {
  padding: 24px 0 30px;
  background: transparent;
  max-width: 1200px;
}

.Title {
  font-size: 32px;
}

.FilterRow {
  margin-top: 31px;
  gap: 20px;
}

.NotificationScroll {
  padding-top: 20px;
  background: transparent;
  overflow: auto;
  max-width: 90%;
  gap: 38px;
}

.NotificationScroll::-webkit-scrollbar {
  display: none;
}
.NotificationScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.EmptyNotificationsText {
  font-size: 18px;
}
