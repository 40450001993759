@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 20px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.pageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-family: 'Ubuntu';
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.cardsContainer {
  max-width: 1196px;
}

.companyCard {
  width: 346px;
  height: 220px;
  border-radius: 6px;
  background: $white;
  padding: 32px 0 0 32px;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(128, 132, 193, 0.1);

  &:hover {
    background: $card-background-hover;
    cursor: pointer;
  }

  img {
    object-fit: contain;
    object-position: right;
  }

  :global {
    .ant-image {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      max-height: 100%;
      width: auto;
    }
  }
}

.cardTitle {
  margin-bottom: 16px;
  span {
    font-family: 'Ubuntu';
    font-size: 20px;
    color: $text-primary;
    font-style: normal;
    line-height: 23px;
  }
}

.cardDescription {
  max-width: 170px;

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
    font-weight: 300;
    white-space: pre-wrap;
  }
}

.subMenuContainer {
  width: 1016px;
  height: 4px;
  border-radius: 2px;
  background: #dadada;
  margin-left: 20px !important;
}

.menuItemWrapper {
  width: 1016px;
  margin-top: 43px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;

  div {
    cursor: pointer;
    height: 40px;

    span {
      font-family: 'Ubuntu';
      font-size: 16px;
      color: $text-primary;
      font-style: normal;
      line-height: 20px;
      font-weight: 300;
    }
  }
}

.activeMenuItem {
  border-bottom: 4px $green solid;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
