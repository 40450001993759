@import '/src/app/theme.scss';

.container {
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: fit-content;
  padding-left: 48px;
  padding-top: 40px;
  padding-bottom: 27px;
}

.header {
  color: $text-primary;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.description {
  color: $text-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.snils {
  height: 56px;
  min-width: 417px;
}

.WarnText {
  color: $primary;
  width: 517px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.WarnNote {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $primary;
  width: 288px;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $primary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  
  path {
    stroke: $primary;
  }
}

.WarnLink {
  color: $primary !important;
  text-decoration: underline !important;
}
