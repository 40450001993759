@import '/src/app/theme.scss';

.PageContainer {
  padding: 28px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.PageContentContainer {
  min-width: 1131px;
  min-height: 671px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 32px;
  margin-top: 24px;
}

.TitlePromotionForm {
  margin-bottom: 26px;

  span {
    color: $text-primary;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.16px;
  }
}

.GeneralDataContainer {
  width: 1131px;
  min-height: 432px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 24px 40px 48px;
}

.PromotionLifetimeDataContainer,
.ParticipatingBranchesContainer,
.ParticipatingDoctorsContainer {
  margin-top: 26px;
  width: 1131px;
  min-height: 296px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 24px 40px 48px;
}

.VisibilityInServicesContainer {
  margin-top: 26px;
  width: 1131px;
  min-height: 166px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 24px 40px 48px;
}

.GeneralDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.FormButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .ButtonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.ButtonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.ButtonSubmitDisabled {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.InputsRowWrapper {
  width: 1047px;
  justify-content: space-between;
}

.SingleFormRow {
  min-width: 858px;
  justify-content: space-between;
  margin-left: 12px;
}

.BannerForm {
  width: 1131px;
  min-height: 474px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
  margin-top: 24px;
}

.BannerVisibilityChkbWrapper {
  span:not(:first-child) {
    margin-left: 8px;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }

  :global {
    span.ant-checkbox-inner {
      margin-left: 0;
    }
  }
}

.FileBackgroundUploader {
  img {
    object-fit: cover;
    border-radius: 6px;
  }

  span {
    color: $primary;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    width: 67px;
  }

  :global {
    .ant-upload-drag {
      height: 160px;
      width: 240px;
      background: #ecedf6;
      border-radius: 6px;
      padding: 0 !important;
    }

    .ant-upload-btn {
      display: inline !important; // to override the default antd hover style
    }

    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
  }
}

.BgPhotoIcons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 8px;
  height: 142px;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }
}

.InputConditions.InputConditions {
  min-height: 116px;
  width: 859px;

  padding-top: 24px;
}

.InputsWrapper {
  width: 418px;

  input {
    width: 418px;
  }
}

.InputsRowWrapper {
  width: 1047px;
  justify-content: space-between;
}

.FormInput {
  height: 56px;
  min-width: 418px;
}

.FormInfoText {
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  span {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }
}

.DatesRadioButtonsWrapper {
  margin-bottom: 24px;
}

.InfiniteDateLabelWrapper {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.DateFormInputWrapper.DateFormInputWrapper {
  input {
    padding-top: 10px;
  }
}

.BranchesPublicInfoText,
.DoctorsPublicInfoText {
  margin-bottom: 22px;
}

.BranchesRadioButtonsWrapper {
  :global {
    .ant-radio-wrapper {
      margin-right: 80px;
    }
  }
}

.CheckboxesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.VisibilityChkbWrapper {
  span:not(:first-child) {
    margin-left: 8px;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    color: $text-primary;
  }

  :global {
    span.ant-checkbox-inner {
      margin-left: 0;
    }
  }
}

.BranchFormItem {
  margin-bottom: 0 !important;
}

.SpecializationSelector {
  margin-right: 16px;
  margin-left: 16px;
  min-width: 860px;

  .SpecializationInput {
    width: 328px;
    height: 40px;
  }

  :global {
    .ant-select-selector {
      padding-top: 5px !important; // to override the default antd hover style
      height: 40px !important; // to override the default antd hover style
    }
  }
}

.DoctorsList.DoctorsList {
  width: 860px;
  margin-top: 29px;
  border: 1px solid $border;
  border-radius: 6px;
  margin-left: 12px;

  :global {
    .ant-list {
      width: 100%;
    }

    .ant-list-item-meta-content {
      margin-left: 16px;
    }

    .ant-list-item {
      padding: 12px 21px 36px 21px;
      min-height: 85px;
    }

    .ant-list-item-meta {
      align-items: center;
      margin-top: 24px;
    }
  }
}

.DoctorPhoto.DoctorPhoto {
  width: 58px;
  height: 58px;
}

.AddButton {
  width: 98px;
  height: 32px;
  background-color: rgba($primary, 0.12);
  border-radius: 6px;

  span {
    color: $primary;
  }
}

.ContentWrapper {
  margin-top: 24px;
}

.SpecializationList {
  margin-right: 156px;
}

.AddButtonWrapper {
  display: flex;
  align-items: center;
}

.AddedDoctorsText {
  margin-left: 12px;
  margin-top: 33px;

  span {
    font-size: 18px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.SelectedDoctorsList.SelectedDoctorsList {
  width: 860px;
  margin-top: 29px;
  border: 1px solid $border;
  border-radius: 6px;
  margin-left: 12px;

  :global {
    .ant-list {
      width: 100%;
    }

    .ant-list-item-meta-content {
      margin-left: 16px;
    }

    .ant-list-item {
      min-height: 85px;
      padding-right: 21px;
      padding-left: 21px;
    }

    .ant-list-item-meta {
      align-items: center;
    }
  }
}

.RemoveDoctorButton {
  svg {
    fill: $primary;
  }
}

.RemoveButtonWrapper {
  :global {
    .ant-btn-default {
      box-shadow: none;

      &:hover {
        border-color: transparent !important;
      }
    }
  }
}

.selectedDoctorsContent {
  display: flex;
  margin: auto 0;
  align-items: center;
}

.DoctorNameText {
  font-size: 14px;
  letter-spacing: 0.2px;
  color: $primary;
  font-style: normal;
  line-height: 18px;
  font-weight: 400;
}

.SpecSelectedDoctorsList {
  margin-right: 222px;
}

.DoctorsListInfoText {
  margin-top: 32px;
  margin-left: 12px;
}

.TextEditorWrapper {
  width: 859px;
  border-radius: 5px;
  padding-bottom: 24px;
}

.TextEditorInfoText {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  span {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
  }
}

.DoctorsListContainer {
  min-height: 250px;
}

.delete {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(250, 96, 96, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}
