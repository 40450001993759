@import '/src/app/theme.scss';

.container {
  position: relative;
}

@mixin Label() {
  position: absolute;
  color: $text-secondary;
  user-select: none;
  transition: 0.2s ease all;
  pointer-events: none;
}

.labelSmall {
  @include Label();

  font-size: 12px;
  line-height: 16px;
}

.labelFull {
  @include Label();

  font-size: 16px;
  line-height: 24px;
}
