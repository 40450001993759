@import '/src/app/theme.scss';

.DatePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  height: 40px;
  background: $white;
  box-shadow: 0px 0px 10px rgba(110, 113, 135, 0.16);
  border-radius: 6px;
  border: 1px solid transparent;

  &:hover {
    border-color: $primary;
  }
}

.DatePicker:global(.ant-picker-focused) {
  border-color: $primary;
  box-shadow: 0 0 0 2px rgba(11, 30, 99, 0.16);
}
