@import '/src/app/theme.scss';

.ScheduleWrapper {
    padding: 24px 0 30px;
    background: transparent;
}

.NoScheduleWrapper {
    height: 100vh;
}

.Title {
    font-size: 32px;
    margin-bottom: 31px;
    padding-left: 40px;
}

.BranchesSelector {
    width: 303px;
    margin-bottom: 20px;
    padding-left: 40px;
}
