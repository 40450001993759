@import '/src/app/theme.scss';

.MainPageWrapper {
  background: transparent;
  padding-bottom: 70px;
}

.NameTitle {
  font-size: 32px;
  margin-top: 28px;
}

.MainStatisticRow {
  display: flex;
  flex-wrap: wrap;
  gap: 46px;
  margin-top: 30px;
}

.FilterRow {
  align-items: center;
  gap: 22px;
}

.FilterText {
  font-size: 18px;
}
