@import '/src/app/theme.scss';

.MarketingCard {
  width: 346px;
  height: 220px;
  border-radius: 6px;
  background: $white;
  padding: 32px 0 0 32px;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(128, 132, 193, 0.1);

  &:hover {
    background: $card-background-hover;
    cursor: pointer;
  }
}

.CardTitle {
  margin-bottom: 16px;
  margin-left: 8.5px;

  span {
    font-size: 20px;
    color: $text-primary;
    font-style: normal;
    line-height: 23px;
  }
}

.CardDescription {
  max-width: 170px;
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 19px;
  min-height: 82px;

  span {
    font-size: 14px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
    font-weight: 300;
    white-space: pre-wrap;
  }
}

.CardIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.CardButtonWrapper {
  width: 201px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;

  .PrimaryButton:disabled {
    background: rgba($text-secondary, 0.16);

    span {
      color: rgba($text-primary, 0.38);
    }
  }

  button {
    width: 145px;
    height: 32px;
    background: rgba($primary, 0.12);

    span {
      font-size: 14px;
      color: $primary;
      font-style: normal;
      line-height: 16px;
      white-space: pre-wrap;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.IconButton.IconButton {
  display: flex;
  justify-content: center;

  :global(.ant-btn-icon) {
    align-self: center;
  }

  svg {
    align-self: center;
    margin: 0 auto;
  }
}
