@import '../../styles/mixins';
@import '/src/app/theme.scss';

.footerContainer {
  background-color: $primary;
  height: 106px;
  max-height: 106px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @include breakpoint(tablet-landscape) {
    height: auto;
    max-height: none;
    flex-wrap: wrap;
    padding: 20px;
  }
}

.n3Logo {
  display: flex;
  align-items: center;
  margin-right: 91px;
  margin-left: 38px;

  @include breakpoint(tablet-portrait) {
    width: 50%;
    margin: 0 0 10px;
    min-width: 300px;
    justify-content: center;
  }
}

.milaLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 120px;

  svg {
    margin-right: 10px;
  }

  @include breakpoint(laptop-small) {
    width: auto;
    margin-right: 40px;
    justify-content: flex-start;
  }

  @include breakpoint(tablet-portrait) {
    width: 50%;
    margin: 0;
    min-width: 300px;
    justify-content: center;
  }
}

.rowsWrapper {
  display: flex;
  flex-direction: column;

  a,
  span {
    color: $white;
    font-weight: 400;
    line-height: 24px;
  }
}

.privatePolicyDesc {
  display: flex;
  align-items: center;
  margin-right: 186px;

  a,
  span {
    font-weight: 300;
    white-space: nowrap;
  }

  @include breakpoint(laptop-small) {
    width: auto;
    margin-right: 40px;
  }

  @include breakpoint(tablet-landscape) {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-top: 20px;
  }

  @include breakpoint(tablet-portrait) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

.markDesc {
  display: flex;
  align-items: center;
  width: 332px;
  margin-right: 32px;

  span {
    font-weight: 300;
  }

  @include breakpoint(tablet-landscape) {
    width: 50%;
    margin-right: 0;
    margin-top: 20px;
    text-align: right;
    justify-content: flex-end;
  }

  @include breakpoint(tablet-portrait) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
