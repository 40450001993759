@import '/src/app/theme.scss';

.Select {
  width: 328px;
  height: 40px;
}

div.inputOrgName {
  :global {
    div.ant-select-selector.ant-select-selector {
      width: 328px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
    }
  }
}
