@import '/src/app/theme.scss';

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 1;
  padding: 56px 205px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: $text-primary;
}

.text {
  margin-top: 16px;
  margin-bottom: 0;
  width: 642px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $text-primary;
  text-align: center;

  & a {
    color: $text-primary;
    text-decoration: underline;
  }
}

.begin {
  margin-top: 44px;
  width: 160px;
  height: 43px;
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: $button-hover;
    color: $white !important;
  }
}
