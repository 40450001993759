@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 20px 60px 0;
  min-height: 700px;
  background-color: $page-background;

  :global {
    .ant-input-password-icon {
      svg {
        width: 18.5px;
        height: 17.5px;
        fill: $primary;
        margin-left: 8px;
        z-index: 1;
      }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      z-index: auto;
    }
  }
}

.titleUserName {
  margin-bottom: 26px;
  display: flex;

  span {
    color: $text-primary;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.16px;
  }
}

.userAvatar {
  background-color: rgba(98, 102, 167, 0.16);
  color: $text-primary;
  align-self: center;
  margin-right: 16px;

  span {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
  }
}

.formContainer {
  width: 1131px;
  min-height: 272px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 24px 40px 48px;
}

.formTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.buttonSubmitDisabled {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(118, 120, 134, 0.16);

  span {
    color: $text-primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
  }
}

.newPasswordForm {
  height: 56px;
  min-width: 418px;

  input {
    width: 321px;
  }
}

.FormInput {
  height: 56px;
  min-width: 418px;
}
