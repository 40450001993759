@import '/src/app/theme.scss';

.StubContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  gap: 24px;
}

.StubTitle {
  font-size: 24px;
  text-align: center;
}

.StubDescription {
  max-width: 700px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}
