@import '/src/app/theme.scss';

.pageTitleWrapper {
  margin-bottom: 32px;

  span {
    font-family: 'Ubuntu';
    font-size: 32px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 38px;
  }
}

.pageContainer {
  padding: 28px 20px 60px 0;
  min-height: 700px;
}

.pageContent {
  width: 1132px;
  min-height: 680px;
  border-radius: 6px;
  margin-top: 32px;
}

.nameTitle {
  font-size: 32px;
  margin-top: 28px;
}

.infoBlock {
  background: $block-background;
  padding: 21px 34px;
  max-width: 820px;
  margin-bottom: 24px;

  a {
    color: $primary;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  & > :global(.ant-typography) {
    color: $primary;
    font-size: 14px;
    line-height: 20px;
  }
}

.cardsContainer {
  max-width: 1196px;
}

.serviceCard {
  width: 346px;
  height: 220px;
  border-radius: 6px;
  background: $white;
  padding: 32px 0 20px 32px;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(128, 132, 193, 0.1);

  &:hover {
    background: $card-background-hover;
    cursor: pointer;
  }
}

.cardTitle {
  margin-bottom: 10px; //12 to adjust layout
  span {
    font-family: 'Ubuntu';
    font-size: 20px;
    color: $text-primary;
    font-style: normal;
    line-height: 23px;
  }
}

.cardDescription {
  max-width: 180px;
  max-height: 95px;
  margin-bottom: 12px; //19 to adjust layout

  span {
    font-family: 'Ubuntu';
    font-size: 14px;
    color: $text-primary;
    font-style: normal;
    line-height: 20px;
    font-weight: 300;
    white-space: pre-wrap;
  }

  .wideDescription {
    width: 200px;
    display: block;
  }
}

.cardIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 0;
}

.cardButtonWrapper {
  width: 201px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;

  button {
    width: 145px;
    height: 32px;
    background: rgba(98, 102, 167, 0.12);

    span {
      font-family: 'Ubuntu';
      font-size: 14px;
      color: $primary;
      font-style: normal;
      line-height: 16px;
      white-space: pre-wrap;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.iconButton {
  display: flex;
  justify-content: center;

  :global(.ant-btn-icon) {
    align-self: center;
  }

  svg {
    align-self: center;
    margin: 0 auto !important;
  }
}

.AssertButton.AssertButton {
  background: rgba(98, 102, 167, 0.12);
  span {
    color: $primary;
  }
}

.ActivateButton {
  background: $primary;

  svg {
    path {
      fill: $white;
    }
  }

  span {
    color: $white;
  }
}
