@import '/src/app/theme.scss';

.CustomTable :global(.ant-table-cell.ant-table-cell) {
  background: transparent;
}

.CustomTable table {
  padding-left: 15px;
}

:global(.ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
  display: none;
}

.CustomTablePagination {
  display: flex;
  margin-top: 30px;
}

:global(.ant-pagination .ant-pagination-total-text) {
  height: auto;
  margin-inline-end: auto;
}

.CustomTable :global(.ant-table-column-title) {
  font-size: 16px;
}
