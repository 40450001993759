@import '/src/app/theme.scss';

.container {
  background: $primary;
  padding: 0 24px;

  span {
    color: $white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}
