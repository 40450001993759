@import '/src/app/theme.scss';
@import '../../../styles/mixins.scss';

.NotificationCard {
  position: relative;
  background: $white;
  width: 100%;
  padding: 32px 46px;
  border-radius: 6px;
  box-shadow: 0px 0px 20px rgba(128, 132, 193, 0.1);
}

.Icon {
  max-width: 103px;
  max-height: 103px;
}

.NotificationTitle {
  font-size: 20px;
  max-width: 205px;
}

.NotificationCardRow {
  justify-content: space-between;
  flex-wrap: nowrap;
  @include breakpoint(tablet-landscape) {
    flex-wrap: wrap;
  }
}

.TitleCol {
  max-width: 290px;
  min-width: 199px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ContentCol {
  padding: 23px;
  border: 1px solid $light-border;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 525px;
}

.ContentTopRow {
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.ContentMainText {
  font-size: 16px;
  max-width: 381px;
}

.ContentMainTextUnseen {
  font-size: 16px;
  max-width: 381px;
  font-weight: 600;
}

.ContentRowName {
  width: 150px;
}

.ContentSubRow {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}

.ActionCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-evenly;
  min-width: 223px;
}

.ButtonRow {
  align-items: end;
}

.NameRow {
  align-items: center;
  gap: 12px;
}

.PaleText {
  font-weight: 300;
}

.Message {
  max-width: 465px;
}

.Link {
  color: $primary;
  &:hover {
    color: rgba($primary, 0.7);
  }
}

.ViewedCircle {
  position: absolute;
  top: 12px;
  left: 12px;
  circle {
    fill: $red;
  }
}
