@import '/src/app/theme.scss';
@import '/src/app/mixins.scss';

.modal {
  & :global(.ant-modal-content) {
    padding: 25px 57px 47px 54px !important;
  }
}

.container {
  width: 100%;
  min-height: 617px;
}

.block {
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  margin-bottom: 40px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: $text-primary;
  margin-bottom: 30px;
}

.input {
  height: 54px;

  &:global(.ant-input) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &::placeholder {
    font-size: 14px !important;
  }
}

.row {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.cost {
  width: 159px;
}

.radio {
  display: flex;
  align-items: center;
  column-gap: 9px;

  input {
    @include CustomRadioColor($primary);
    cursor: pointer;
  }

  span {
    font-size: 16px;
    color: $text-secondary;
  }
}

.attributes {
  font-size: 16px;
  color: $text-secondary;
  line-height: 24px;
}

.showmode {
  font-size: 16px;
  color: $text-primary;
}

.buttons {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

@mixin Button {
  height: 48px;
}

.cancel {
  @include Button();
  background-color: rgba($color: $primary, $alpha: 0.12);
  color: $primary;
}

.submit {
  @include Button();
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: $white;
    color: $primary;
  }
}

.delete {
  @include Button();
  background-color: rgba(250, 96, 96, 0.12);
  color: $primary;
}

.createForm {
  .cancel {
    width: 257px;
  }

  .submit {
    width: 257px;
  }
}

.editForm {
  .delete {
    width: 251px;
  }

  .cancel {
    width: 142px;
  }

  .submit {
    width: 225px;
  }
}

.disabledPopconfirm {
  color: $text-secondary;
  cursor: not-allowed;
  transition: none;
  background-color: $disabledInput;

  &:hover {
    color: $text-secondary !important;
    border: none;
  }
}
