@import '/src/app/theme.scss';

.StubContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 24px;
}

.StubTitle {
  font-size: 24px;
  text-align: center;
}

.StubDescription {
  max-width: 700px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}

.ButtonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba($primary, 0.12);

  span {
    color: $primary;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.GoToMainButton {
  display: block;
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba($primary, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.GoToMainButton:hover {
  color: $primary;
  border: 1px solid;
}

.ButtonSubmit {
  display: block;
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.ButtonSubmit:hover {
  color: $white;
  background-color: rgba($primary, 0.08);
  border: 1px solid;
  color: $primary;
}

.FormButtonsContainer {
  justify-content: center;
}

.iconContainer img {
  max-width: 138px;
}
