@import '/src/app/theme.scss';
@import '/src/app/mixins.scss';

.pageContainer {
  padding: 24px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.educationFormContainer {
  width: 1131px;
  min-height: 168px;
  padding: 40px 48px;
  margin-top: 16px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.generalDataHasAccessInput {
  margin-bottom: 10px;

  //input {
  //  margin-bottom: 5px;
  //}
}

.radio {
  display: flex;
  align-items: center;
  column-gap: 9px;
  margin-bottom: 10px;

  &:has(input[disabled]) {
    opacity: 0.5;
  }

  input {
    @include CustomRadioColor($primary);
    cursor: pointer;
  }

  span {
    font-size: 16px;
    color: $text-secondary;
  }
}

.AccessCol {
  margin-bottom: 10px;
}

.formButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1131px;
  margin-left: 0 !important;

  .buttonSubmit {
    width: 257px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    &[disabled] {
      background: rgba($text-secondary, 0.16);
      opacity: 0.5;
      span {
        color: $text-primary;
      }
    }
  }
}

.buttonCancel {
  width: 257px;
  height: 48px;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);

  span {
    color: $primary;
    font-family: 'Ubuntu';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  &[disabled] {
    opacity: 0.5;
    background: transparent;

    span {
      color: $text-primary;
    }
  }
}

.generalDataHasAccessButton {
  margin-bottom: 30px;

  button {
    border-radius: 6px;
    background: $primary;
  }

  span {
    color: $white;
    font-family: 'Ubuntu';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
  }

  &[disabled] {
    opacity: 0.5;

    span {
      color: $text-primary;
    }
  }
}

.passwordInfo {
  border: 1px solid $border;
  border-radius: 6px;
  padding: 10px;
  max-width: 568px;
}

.infoBlock {
  color: $primary;
  border-radius: 6px;
  background: rgba(98, 102, 167, 0.12);
  padding: 34px;
  max-width: 858px;
  margin-bottom: 20px;
}

.infoNote {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $primary;
  width: 299px;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $primary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
  svg path {
    stroke: $primary;
  }
}

.WarnText {
  color: $red;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0;
}

.WarnNote {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $red;
  width: max-content;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $red;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.WarnLink {
  color: $red !important;
  text-decoration: underline !important;
}

.SuccessMessage {
  display: flex;
  max-width: 570px;
}

.SuccessMessageIcon {
  margin-right: 10px;
  min-width: 13px;
  margin-top: 8px;
}
