@import '/src/app/theme.scss';

.pageContainer {
  padding: 28px 40px 60px 0;
  min-height: 700px;
  background-color: $page-background;
}

.pageContentContainer {
  min-width: 1131px;
  min-height: 671px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 32px;
  margin-top: 24px;
}

.inputOrgName {
  margin-right: 16px;
  margin-left: 16px;

  :global {
    .ant-select-selector {
      padding-top: 5px !important;
      height: 40px !important;
    }
  }
}

.inputStatusType {
  margin-left: 16px;

  :global {
    .ant-select-selector {
      padding-top: 5px !important;
      height: 40px !important;
    }
  }
}

.addUserButton {
  width: 48px !important;
  height: 40px;
  border-radius: 6px;

  &:hover {
    border-color: transparent !important;
  }
}

.filtersPanel {
  align-items: center;
}

.paginationWrapper {
  display: flex;

  margin-top: 24px;

  :global {
    .ant-pagination-total-text {
      margin-right: auto !important;
    }
  }
}

.totalPaginationResults {
  font-family: 'Ubuntu';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: $text-primary;
  font-style: normal;
  line-height: 20px;
}

.cellLinkText {
  a {
    font-family: 'Ubuntu';
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $primary !important;
    font-style: normal;
    line-height: 18px;
  }
}

:global {
  th.ant-table-cell {
    font-family: 'Ubuntu';
    font-size: 16px;
    letter-spacing: 0.2px;
    color: $text-primary !important;
    font-style: normal;
    line-height: 24px;
    font-weight: 500 !important;
  }
}

.colorCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $green;
  align-self: center;
  margin-right: 8px;
}

.statusTextWrapper {
  display: flex;
}

.cellBranchesCount {
  text-align: center;
}

.pageLoader {
  margin-top: 150px;
  display: block;
  min-height: 700px;
}

.FormInput {
  width: 328px;
  height: 40px;
}
