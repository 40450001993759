@import '/src/app/theme.scss';

.ChatWrapper {
  padding: 24px 0 30px;
  background: transparent;
  max-width: 1200px;
  
  & :global {
    .chat-container.chat-container {
      background-color: $white;
      border-radius: 6px;
      box-shadow: 0 0 20px 0 rgba(128,132,193,.1);
      overflow: hidden;
      font-family: 'Ubuntu', sans-serif;
      color: $text-primary;

      & svg path {
        fill: $chatsSvg;
      }

      & svg.chat-settings circle {
        fill: $primary;
      }

      & .chat-send-btn svg path {
        fill: $primary;
      }

      & .chat-send-btn.chat-send-btn_disabled svg path {
        fill: $chatsSvg;
      }

      & .message-wrapper.reverse .message-box-text {
        border-color: $inputBorder;

        &:before {
          border-color: transparent transparent $inputBorder transparent;
        }
      }

      & .message-box-settings-container .message-box-settings-item:hover {
        color: $primary;

        & svg path {
          fill: $primary;
        }
      }

      & .message-box-settings-item.outer-click-btn {
        &:first-child {
          color: $primary;
        }

        &:last-child {
          color: $red;
        }
      }

      & .message-box-text {
        color: $text-primary;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
      }

      & .message-box-author {
        font-size: 16px;
        color: $text-primary;
      }

      & .message-attachment {
        color: #15181F;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      & .chat-input-container, .chat-item_selected, .chat-item:hover  {
        background-color: $page-background;
      }

      & .chat-item__name {
        font-size: 14px;
      }

      & .chat-item__user-name {
        font-size: 12px;
      }

      & .chat-item__name, .chat-item__user-name {
        color: $text-primary;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & .chat-item__text {
        font-size: 12px;
        color: $text-secondary;
      }
    }

    .chat-name.chat-name {
      font-size: 18px;
      color: $text-primary;

      & svg {
        display: none;
      }
    }
  }
}

.Title {
  font-size: 32px;
  margin-bottom: 31px;
}
