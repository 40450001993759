@import '/src/app/theme.scss';

.Input {
  margin-bottom: 0px;
}

.Input :global(.ant-input) {
  padding: 4px 11px;
  margin: 0;
}
