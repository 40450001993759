@import '../../styles/mixins';
@import '/src/app/theme.scss';

.logo {
  min-width: 81px;
  display: flex;
  justify-content: flex-start;
  margin: auto 25px auto 0;
  padding-left: 40px;
  cursor: pointer;
}

.menuLabel {
  // ovveride default antd styles;
  color: $text-primary !important;
  font-weight: 400 !important;
  letter-spacing: 0.16px !important;
}

.headerContainer {
  height: auto !important;
  background-color: white !important;
  display: flex;
  line-height: 80px !important;
  padding-inline: 0 !important;
  position: relative;
  align-items: center;
}

.dividerLogo {
  height: 45px;
  margin-right: 20px;
}

.searchInput {
  min-width: 345px;
  margin-right: 68px;

  @include breakpoint(desktop) {
    margin-right: 30px;
  }
  @include breakpoint(tablet-landscape) {
    margin-right: 0;
    min-width: auto;
  }
}

.logoClinic {
  display: flex;
  justify-content: center;
  margin: auto 0;

  a {
    display: flex;
  }

  svg {
    margin-right: 91px;

    @include breakpoint(desktop) {
      margin-right: 30px;
    }
  }
}

a.LabelsWrapper {
  display: flex;
  align-items: center;
  color: $text-primary;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  &:hover {
    color: $text-primary;
    background-color: rgba(0, 0, 0, 0.06);
  }
}

.activeNavBarLink {
  border-bottom: 4px $green solid;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding-bottom: 4px;
  margin-top: 4px;
}

.inputPrefix {
  color: $primary;
  svg {
    width: 17.4px;
    height: 17.4px;
  }
}

.userInfoContainer {
  display: flex;
  max-height: 80px;
  gap: 8px;
  margin-left: auto;
  border-radius: 6px;
  min-width: 178px;
  align-items: center;

  &:hover {
    // background: rgba(98, 102, 167, 0.08);
    cursor: pointer;
  }

  div {
    display: flex !important;
  }

  li {
    display: flex !important;

    &:hover {
      background: rgba(98, 102, 167, 0.08);
    }
  }

  ul {
    border: none;
  }

  .goToN3Btn {
    background: rgb(98, 102, 167, 0.12);
    color: #6266a7;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 6px;
    padding: 0px 24px;
    margin-right: 8px;
  }

  @include breakpoint(tablet-landscape) {
    order: -1;
    flex-direction: column;
    align-items: start;
  }
}

.userDescription {
  color: $primary;
}

.userAvatar {
  background-color: rgba(98, 102, 167, 0.16);
  color: $text-primary;
  align-self: center;
  margin-left: 8px;

  span {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
  }
}

.userNameContainer {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  justify-content: center;
  margin-left: auto;

  span {
    align-self: flex-end;
  }
}

.linksContainer {
  //margin-right: 58px;
  min-width: 490px;
  display: flex;
  align-items: center;

  @include breakpoint(laptop) {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid rgba(5, 5, 5, 0.06);
  }

  @include breakpoint(tablet-landscape) {
    flex-direction: column;
    align-items: flex-start;

    button {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }
  }

  @include breakpoint(tablet-portrait) {
    min-width: auto;
    width: 100%;
  }
}

.burgerContainer {
  display: flex;
  min-height: 80px;
  margin-left: 110px;
  width: 100%;

  @include breakpoint(laptop) {
    flex-wrap: wrap;
  }

  @include breakpoint(tablet-landscape) {
    position: absolute;
    right: 0;
    top: 80px;
    background: #fff;
    height: calc(100vh - 80px);
    max-height: 400px;
    overflow-y: auto;
    flex-direction: column;
    padding: 20px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    flex-wrap: nowrap;
    min-height: 400px;
    display: flex;
    opacity: 0;
    z-index: -100;
    &.open {
      opacity: 1;
      z-index: 100;
    }
  }

  @include breakpoint(tablet-portrait) {
    width: 100%;
  }
}

.breadcrambsWrapper {
  min-height: 36px;
  background-color: $white;
  border: 0.5px solid #dadada;
  display: flex;
  align-items: center;

  li {
    display: flex;
    align-items: center;
  }
}

.separatorIcon {
  margin: 0 24px;
  display: flex;
}

.navBarContainer {
  //max-height: 117px;
  height: auto;
  background: $page-background;
}

.siderMenu {
  background-color: transparent;
  border-inline-end: transparent !important;

  span {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: -2%;
    color: $text-primary;
  }

  li {
    padding-left: 40px !important;
    margin-bottom: 20px !important;
  }
}

.siderWrapper {
  background-color: $page-background !important;
  margin-top: 32px;
}

.triggerButton {
  display: flex;
  align-items: center;
  border-top: 0.5px solid #dadada;
  height: 120px;
  width: 226px;

  span {
    color: $text-primary;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  svg {
    margin-right: 12px;
    margin-left: 44px;
  }
}

:global {
  .ant-layout-sider-trigger {
    background: $page-background !important;
    bottom: auto !important;
    margin-top: 40px;
  }
}

.triggerCollapsedButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.5px solid #dadada;
  height: 120px;
  width: 120px;
}

:global {
  .ant-layout-has-sider {
    background: transparent;
  }
}

:global {
  .ant-layout-footer {
    padding: 0 !important;
    height: 106px;
  }

  .ant-layout-sider-children {
    height: 280px !important;
  }

  .ant-layout-sider-trigger {
    position: static !important;
  }

  .ant-layout-has-sider {
    background-color: $page-background !important;
  }
}

.sidebarWrapperSmall {
  :global {
    .ant-layout-sider-children {
      height: 60px !important;
    }
  }
}

.userSubMenuTitle {
  display: flex;
}

:global {
  .ant-menu-submenu-open:after,
  .ant-menu-submenu-selected:after,
  .ant-menu-submenu:hover::after {
    border-bottom-color: $white !important;
    border: none !important;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background-color: transparent !important;
    span {
      font-weight: 400;
    }
  }
}

.activeBreadcrumb {
  a {
    color: #bebfc8;
  }
}

.inactiveBreadcrumb {
  a {
    color: $primary;
  }
}
