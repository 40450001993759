@import '/src/app/theme.scss';

.BranchSelector {
  width: 418px;
  margin-left: 12px;
  margin-right: 12px;
}

.FilterSelector {
  width: 196px;
  margin-left: 12px;
  margin-right: 12px;
  :global {
    .ant-select .ant-select-arrow {
      z-index: 3;
    }
  }
}

.generalDataFormTitle {
  margin-bottom: 32px;
  display: flex;

  span {
    font-family: 'Ubuntu';
    font-size: 24px;
    letter-spacing: 0.16px;
    color: $text-primary;
    font-style: normal;
    line-height: 24px;
  }
}

.generalDataContainer {
  width: 1131px;
  min-height: 273px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(128, 132, 193, 0.1);
  background: $white;
  padding: 40px 48px;
}

.pageContainer {
  padding: 0 40px 60px 40px;
  min-height: 700px;
  background-color: $page-background;
}

.formButtonsContainer {
  display: flex;
  margin-bottom: 32px;

  .buttonSubmit {
    width: 213px;
    height: 48px;
    border-radius: 6px;
    background: $primary;

    &:hover {
      background: $button-hover;
    }

    span {
      color: $white;
      font-family: 'Ubuntu';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
}
