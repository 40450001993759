@import '/src/app/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 28px;
}

.addSpecialityTextWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 13px;
  }

  span {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: $primary;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
  }

  &:hover {
    span {
      color: darken(#6266a7, 20%);
    }

    svg path {
      stroke: darken(#6266a7, 20%);
    }
  }
}

.addSpeciality {
  display: flex;
  align-items: center;
}
