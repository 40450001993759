@import '/src/app/theme.scss';

.container {
  color: $text-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;

  &__link {
    display: inline;
    color: $primary;
    cursor: pointer;
  }
}
