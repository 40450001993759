@import '/src/app/theme.scss';
@import '../grid.scss';

.container {
  @include AmenitiesGrid;

  border-bottom: 1px solid $border;

  & > div {
    font-size: 16px;
    font-weight: 500;
    color: $text-primary;
  }
}

.create {
  width: 40px;
  height: 40px;
  background-color: $green;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;

  & svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  &:hover {
    background-color: $white !important;
    border-color: $green !important;
    color: $green !important;
  }
}

.hidden {
  visibility: hidden;
}

.speciality {
  display: flex;
  align-items: center;
  column-gap: 11px;
}

.textCenter {
  text-align: center;
}
