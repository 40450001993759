.float-label {
  position: relative !important;
}

.label {
  font-size: 16px !important;
  color: #767886 !important;
  line-height: 16px !important;
  font-weight: 400;
  position: absolute !important;
  pointer-events: none;
  left: 12px !important;
  top: 12px !important;
  transition: 0.2s ease all;
  padding-top: 6px;
  background-color: #ffffff;
  width: 85%;
  z-index: 2;
}

.labelDisabled {
  background-color: rgb(248, 245, 245);
}

.labelClassDisabled {
  font-size: 16px !important;
  color: #767886 !important;
  line-height: 16px !important;
  font-weight: 400;
  position: absolute !important;
  pointer-events: none;
  left: 12px !important;
  top: 12px !important;
  transition: 0.2s ease all;
  padding-top: 6px;
  background-color: rgba(0, 0, 0, 0.25);
  width: 85%;
}

.label-float {
  top: 1px !important;
  font-size: 12px !important;
  background: transparent;
}

.suffixIcon {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 18px;
}
