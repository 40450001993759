@import '/src/app/theme.scss';

@mixin AmenitiesGrid() {
  width: 100%;
  min-height: 50px;
  display: grid;
  grid-template-columns: 52px 1fr 115px 115px 115px 115px 145px 30px;
  grid-template-areas: 'button name cost clinic online home widget delete';
  align-items: center;

  & > div {
    padding: 16px;

    &:nth-child(n + 5) {
      display: flex;
      justify-content: center;
    }
  }
}
